import { cn } from '@/utils/classname';
import { getFileTreeNodeIconClass } from '@/utils/getIconClassName';
import { FileTreeNode, UploadSession, useUploadSession } from '@skand/uploader';
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { basename, extname } from 'path-browserify';
import { useMemo } from 'react';
import { TableDense } from './TableDense';
import { UploadStatus } from './UploadStatus';

export interface FileTreeNodeWithDepth {
  depth: number;
  iconClass: string;
  name: string;
  node: FileTreeNode;
  type: string;
}

const columnHelper = createColumnHelper<FileTreeNodeWithDepth>();

export interface UploadDetailsProps {
  uploadSession: UploadSession;
}

export const UploadDetails = ({ uploadSession }: UploadDetailsProps) => {
  const { fileTreeRef } = useUploadSession(uploadSession);

  const fileTreeNodes: FileTreeNodeWithDepth[] = useMemo(() => {
    const nodes: FileTreeNodeWithDepth[] = [];
    fileTreeRef.current.traverse((node, depth) => {
      if (node.absolutePath === '/') return;
      nodes.push({
        depth,
        iconClass: getFileTreeNodeIconClass(node),
        name: basename(node.absolutePath),
        node,
        type: node.type === 'folder' ? 'FOLDER' : extname(node.absolutePath).slice(1).toUpperCase(),
      });
    });
    return nodes;
  }, [fileTreeRef]);

  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'name',
        cell: ({ row }) => {
          return (
            <div className="flex items-center" title={row.original.name}>
              <div
                className="flex-none"
                style={{ width: `calc(12px * ${row.original.depth - 1})` }}
              />
              <div className={cn(row.original.iconClass, 'flex-none')} />
              <div className="ml-2 overflow-hidden text-ellipsis whitespace-nowrap text-nowrap">
                {row.original.name}
              </div>
            </div>
          );
        },
        enableSorting: false,
        size: 240,
      }),
      columnHelper.accessor('type', {
        header: 'file type',
        cell: ({ getValue }) => getValue(),
        enableSorting: false,
        size: 100,
      }),
      columnHelper.display({
        id: 'status',
        header: 'status',
        cell: ({ row }) => {
          return <UploadStatus nodeWithDepth={row.original} uploadSession={uploadSession} />;
        },
        enableSorting: false,
        size: 240,
      }),
    ];
  }, [uploadSession]);

  const table = useReactTable({
    data: fileTreeNodes,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <TableDense table={table} />;
};
