import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { SearchOption } from '@/constants/node';
import { cn } from '@/utils/classname';
import * as Select from '@radix-ui/react-select';

const options = [
  { value: SearchOption.LOCAL, label: 'Current folder', dataAnalyticsEventObject: ANALYTICS_EVENT_OBJECT.SEARCH_CURRENT_FOLDER },
  { value: SearchOption.RECURSIVE, label: 'All files within', dataAnalyticsEventObject: ANALYTICS_EVENT_OBJECT.SEARCH_RECURSIVELY },
];

interface SearchOptionSelectProps {
  value: SearchOption;
  onValueChange: (value: string) => void;
}

export const SearchOptionSelect = ({
  value = SearchOption.LOCAL,
  onValueChange,
}: SearchOptionSelectProps) => {
  return (
    <Select.Root value={value} onValueChange={onValueChange}>
      <Select.Trigger asChild>
        <div
          className={cn(
            'px-12px py-8px w-30',
            'typo-text-s b-1 b-solid b-rd-1 b-neutral-400',
            'flex justify-between items-center',
            'color-neutral-600 hover:cursor-pointer',
          )}
        >
          <Select.Value placeholder="Select search option" />
          <Select.Icon className="i-skand-dropdown color-neutral-400" />
        </div>
      </Select.Trigger>
      <Select.Portal>
        <Select.Content
          className={cn(
            'z-1 bg-neutral-100 p-1 w-30',
            'typo-text-s b-1 b-solid b-rd-1 b-neutral-400',
          )}
          position="popper"
          side="bottom"
          sideOffset={5}
        >
          <Select.Viewport>
            {options.map(({ value, label, dataAnalyticsEventObject }) => (
              <Select.Item
                className={cn(
                  'hover:cursor-pointer hover:bg-neutral-200',
                  'color-neutral-800 p-2',
                  'outline-none',
                )}
                data-analytics-event-object={dataAnalyticsEventObject}
                key={value}
                value={value}
              >
                <Select.ItemText>{label}</Select.ItemText>
              </Select.Item>
            ))}
          </Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  );
};
