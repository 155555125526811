import { ImageThumbnailIcon, VideoThumbnailIcon } from '@/components/IconButton';
import { IconBottomRight } from '@/components/IconButton/IconBottomRight';
import { NODE_KIND } from '@/constants/node';
import { DATASETS_FOLDERS } from '@/constants/paths';
import { useImageFile } from '@/hooks/useImageFile';
import { useParentNodesChainByParentNodeIdQuery } from '@/hooks/useParentNodesChainByParentNodeIdQuery';
import { usePreviewUrls } from '@/hooks/usePreviewUrls';
import { cn } from '@/utils/classname';
import {
  getIconClassName,
  isImageExtension,
  isPdfExtension,
  isVideoExtension,
} from '@/utils/getIconClassName';
import { isDefined } from '@/utils/misc';
import { CheckBox, ImagePreview, VideoPreview } from '@skand/ui';
import { Getter, Row } from '@tanstack/react-table';
import { useCallback, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { isEmpty } from '../../../utils/empty';
import { FoldersPageTableData } from './useFoldersPageColumns';
import { clearRecentlyCreatedFolderNodes } from '@/stores/recentlyCreatedFolderNodesStore';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

export interface NameCellProps {
  getValue: Getter<string>;
  row: Row<FoldersPageTableData>;
  showParentNodesNameChain: boolean;
}

export const NameCell = ({ row, getValue, showParentNodesNameChain }: NameCellProps) => {
  const { id: parentNodeUrlId } = useParams<{ id: string }>();

  const name = getValue();
  const isFolder = row.original.kind === NODE_KIND.FOLDER_NODE;
  const isImage = isImageExtension(row.original.extension);
  const isVideo = isVideoExtension(row.original.extension);
  const isPdf = isPdfExtension(row.original.extension);

  const willFetchParentNodeChain =
    showParentNodesNameChain || (row.original.parentNodeId !== null && !parentNodeUrlId);

  const { file } = useImageFile((isImage && row.original.fileId) || null);
  const signedUrl = usePreviewUrls(
    (isVideo || isPdf) && row.original.fileId ? [row.original.fileId] : null,
  );

  const { parentNodesChain } = useParentNodesChainByParentNodeIdQuery(
    willFetchParentNodeChain ? row.original.parentNodeId ?? '' : undefined,
  );

  const parentNodesNameChain = useMemo(() => {
    const parentNodesNames: string[] = [];

    parentNodesChain.forEach(parentNode => {
      const isCurrentParentNode = parentNode?.id === parentNodeUrlId;
      if (isDefined(parentNode) && isDefined(parentNode?.name) && !isCurrentParentNode) {
        parentNodesNames.push(parentNode.name);
      }
    });

    return parentNodesNames.reverse().join(' / ');
  }, [parentNodeUrlId, parentNodesChain]);

  const hasParentNodesNameChain = parentNodesNameChain.length > 0;

  const [enabledPreviewImage, setEnabledPreviewImage] = useState(false);
  const [enabledPreviewVideo, setEnabledPreviewVideo] = useState(false);

  const previewUrl = signedUrl?.[0]?.signedGetObjectUrl ?? null;

  const handleClickFolder = useCallback(() => {
    clearRecentlyCreatedFolderNodes();
  }, []);

  const handlePreview = async () => {
    if (isImage) setEnabledPreviewImage(true);
    if (isVideo && previewUrl) setEnabledPreviewVideo(true);
  };

  const iconSlot = (
    <div className={cn('flex-none', getIconClassName[row.original.extension])}>
      {isImage && <ImageThumbnailIcon />}
      {isVideo && <VideoThumbnailIcon />}
    </div>
  );

  const nameSlot = (
    <p
      className={cn(
        'typo-text-s color-neutral-800 no-underline whitespace-nowrap text-ellipsis overflow-hidden flex-1',
      )}
    >
      {name}
    </p>
  );

  const nameWithParentSlot = (
    <div className="flex-1 overflow-hidden">
      <div className="flex items-center gap-1 color-neutral-400">
        {IconBottomRight}
        {nameSlot}
      </div>

      <p
        className="overflow-hidden text-ellipsis whitespace-nowrap color-neutral-500 typo-link-xs"
        title={parentNodesNameChain ?? undefined}
      >
        {parentNodesNameChain}
      </p>
    </div>
  );

  const renderName = () => {
    if (isFolder) {
      return (
        <Link
          className="contents"
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.FOLDER_NAME}
          to={{
            pathname: `${DATASETS_FOLDERS}/${row.original.id}/files`,
            search: window.location.search,
          }}
        >
          <div className={cn('contents cursor-pointer')} onClick={handleClickFolder}>
            {iconSlot}
            {hasParentNodesNameChain ? nameWithParentSlot : nameSlot}
          </div>
        </Link>
      );
    }

    if (isPdf) {
      return (
        <a className="contents cursor-pointer" href={previewUrl ?? undefined}>
          {iconSlot}
          {hasParentNodesNameChain ? nameWithParentSlot : nameSlot}
        </a>
      );
    }

    return (
      <div
        className={cn('contents', (isImage || isVideo) && 'cursor-pointer')}
        onClick={isImage || isVideo ? handlePreview : undefined}
      >
        {iconSlot}
        {hasParentNodesNameChain ? nameWithParentSlot : nameSlot}
      </div>
    );
  };

  return (
    <div className="w-full flex items-center gap-2" >
      <CheckBox
        checked={row.getIsSelected()}
        className="flex-none hover:cursor-pointer"
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />

      {renderName()}

      {enabledPreviewImage && !isEmpty(file) && !isEmpty(file.signedGetObjectUrl) && (
        <ImagePreview
          downloadable
          zoomable
          close={() => setEnabledPreviewImage(false)}
          imageFileName={name}
          url={file.signedGetObjectUrl}
        />
      )}

      {enabledPreviewVideo && previewUrl && (
        <VideoPreview close={() => setEnabledPreviewVideo(false)} url={previewUrl} />
      )}
    </div>
  );
};
