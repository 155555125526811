export const ImageThumbnailIcon = () => {
  return (
    <svg fill="none" height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
      <rect height="19" rx="1.5" stroke="#726F74" width="19" x="0.5" y="0.5" />
      <circle cx="13.5" cy="5.5" r="2" stroke="#726F74" />
      <path
        d="M0.5 13.5L5 8L17.5 19.5"
        stroke="#726F74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 13.5L14.5 11L19.5 15.5"
        stroke="#726F74"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
