import MoveMultipleFiles from './MoveMultipleFiles';
import { DownloadMultipleFiles } from './DownloadMultipleFiles';
import { FoldersPageTableData } from './useFoldersPageColumns';

interface BatchActionFloatingToolbarProps {
  selectedRows: FoldersPageTableData[];
}

export const BatchActionFloatingToolbar = ({ selectedRows }: BatchActionFloatingToolbarProps) => {
  if (selectedRows.length === 0) return null;
  return (
    <div className="absolute bottom-14 z-1 h-[44px] flex items-center gap-4 border-1 border-neutral-400 rounded-md border-solid bg-white px-4 py-2 shadow-md">
      <p className="color-neutral-500 typo-text-s">
        {selectedRows.length} selected file{selectedRows.length > 1 ? 's' : ''}
      </p>

      <div className="h-full b-r-neutral-400 b-r-solid" />

      <MoveMultipleFiles selectedRows={selectedRows} />
      <DownloadMultipleFiles selectedRows={selectedRows} />
    </div>
  );
};
