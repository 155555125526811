import { queryClient } from '@/graphql/client';
import { useEntitlements } from '@/hooks/useEntitlements';
import { setIsUploadModalOpen, useDownloadStore } from '@/stores/download';
import { cn } from '@/utils/classname';
import logger from '@/utils/logger';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@skand/ui';
import { useUploader } from '@skand/uploader';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FileSection } from './FileSection/FileSection';
import { OptionSection } from './OptionSection';
import { ProjectSection } from './ProjectSection';
import { useCreateProjectNodeFiles, useCreateProjectNodeFolder } from './mutations';
import {
  accumulateFiles,
  incrementFilesUploaded,
  resetUploadStore,
  setFileGraph,
  setProgressOverall,
  updateUploadProgress,
  useUploadStore,
} from './uploadStore';
import { ChunkOptions, NodeItem, buildTree, createProjectNodes, displayErrors } from './utils';
import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';

interface UploadProps {
  disabled: boolean;
}

export const UploadLegacy = ({ disabled }: UploadProps) => {
  useEffect(() => {
    logger.debug('legacy uploader mounted');
  }, []);

  const { id } = useParams<{ id: string }>();

  const fileGraph = useUploadStore(state => state.fileGraph);

  const [projectId, setProjectId] = useState<null | string>(null);
  const [parentNodeId, setParentNodeId] = useState<null | string>(null);
  const { isUploadModalOpen } = useDownloadStore(state => state);

  // error includes uploadError
  const [error, setError] = useState<null | Error>(null);
  const [uploadError, setUploadError] = useState<null | Error>(null);
  const [isProjectError, setIsProjectError] = useState<boolean>(false);

  // block errors before upload gets initialized
  const shouldBlockErrorsRef = useRef(true);

  const [chunkOption, setChunkOption] = useState<ChunkOptions>('500M');

  const chunkSize = useMemo(() => {
    const _1M = 1024 * 1024;
    const _1G = 1024 * _1M;
    if (chunkOption === '5M') return 5 * _1M;
    if (chunkOption === '50M') return 50 * _1M;
    if (chunkOption === '500M') return 500 * _1M;
    if (chunkOption === '5G') return 5 * _1G;
    return undefined;
  }, [chunkOption]);

  const handleChangeProjectId = useCallback((id: null | string) => {
    setProjectId(prev => {
      if (prev === id) return prev;
      setParentNodeId(null);
      return id;
    });
  }, []);

  /**
   * canAddFiles: allow drop files in
   * canCancel: cancel button is clickable
   * canUpload: upload button is clickable
   * canSave: save button is visible and clickable
   * canSaveToProject: project section is interactive
   *
   * initial:
   *   - canAddFiles: true
   *   - canCancel: true
   *   - canUpload: false
   *   - canSave: false
   *   - canSaveToProject: true
   * after addFiles (preparing):
   *   - canAddFiles: false
   *   - canCancel: true
   *   - canUpload: false
   *   - canSave: false
   *   - canSaveToProject: true
   * after fileNodesImporting (prepared)
   *   - canAddFiles: false
   *   - canCancel: true
   *   - canUpload: true
   *   - canSave: false
   *   - canSaveToProject: true
   * after click upload (uploading)
   *   - canAddFiles: false
   *   - canCancel: false
   *   - canUpload: false
   *   - canSave: false
   *   - canSaveToProject: true
   * after fileNodesImported (uploaded) (upload complete)
   *   - canAddFiles: false
   *   - canCancel: false
   *   - canUpload: false
   *   - canSave: true
   *   - canSaveToProject: false
   */
  const [stage, setStage] = useState<
    'initial' | 'preparing' | 'prepared' | 'uploading' | 'uploaded'
  >('initial');

  const canAddFiles = stage === 'initial';
  const canCancel = stage !== 'uploaded';
  const canUpload = stage === 'prepared';
  const canSave = stage === 'uploaded';
  const canSaveToProject = stage !== 'uploaded';
  const canChangeOptions = stage !== 'uploading' && stage !== 'uploaded';

  const { addFiles, on, off, upload, uploadState, reset, retry, uploadRetry } = useUploader({
    featureType: 'FILE_SYSTEM',
    persistType: 'IMMEDIATE',
    systemFolderNodeId: id,
    chunkSize,
  });

  const createProjectNodeFiles = useCreateProjectNodeFiles();
  const createProjectNodeFolder = useCreateProjectNodeFolder();
  const createProject = useCallback(async () => {
    const root = uploadState.current.graph?.get('/');
    if (projectId && root) {
      try {
        await createProjectNodes(
          uploadState.current.graph,
          root,
          projectId,
          parentNodeId,
          createProjectNodeFolder,
          createProjectNodeFiles,
        );
      } catch (e) {
        setIsProjectError(true);
        setError(e as Error);
        throw e;
      }
    }
  }, [createProjectNodeFiles, createProjectNodeFolder, parentNodeId, projectId, uploadState]);

  const handleReset = useCallback(() => {
    reset();
    resetUploadStore();
    setStage('initial');
    setProjectId(null);
    setParentNodeId(null);
    setUploadError(null);
    setError(null);
  }, [reset]);

  const handleCancel = useCallback(() => {
    shouldBlockErrorsRef.current = true;
    setIsUploadModalOpen(false);
    handleReset();
  }, [handleReset]);

  const handleUpload = useCallback(async () => {
    setStage('uploading');
    upload();
  }, [upload]);

  const handleRetry = useCallback(async () => {
    if (uploadError) {
      setUploadError(null);
      setError(null);
      uploadRetry();
    } else if (error) {
      if (isProjectError) {
        setIsProjectError(false);
        setError(null);
        await createProject();
        setStage('uploaded');
      } else {
        setError(null);
        retry();
      }
    }
  }, [createProject, error, isProjectError, retry, uploadError, uploadRetry]);

  useEffect(() => {
    const onAddFiles = (files: { file: File }[]) => {
      shouldBlockErrorsRef.current = false;
      setStage('preparing');
      accumulateFiles(files.map(file => file.file));
    };

    const onFileTracked = () => {
      setStage('prepared');
      setFileGraph(prevItems => {
        return prevItems.map(item => ({ ...item, status: 'prepared' }));
      });
    };

    const onFileImported = async () => {
      queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      queryClient.invalidateQueries(useEntitlements.queryKey);
      await createProject();
      setStage('uploaded');
    };

    const onProgress = async ({ progress }: { progress: number }) => {
      setProgressOverall(progress);
    };

    const onUpdateGraph = () => {
      setFileGraph(prev => {
        const nodes: NodeItem[] = [];
        const root = uploadState.current.graph?.get('/');
        buildTree(uploadState.current.graph, root, 0, nodes, prev);
        return nodes;
      });
    };

    const onUploadProgress = ({
      bytesTotal,
      bytesUploaded,
      fileId,
      progress,
    }: {
      bytesTotal: number;
      bytesUploaded: number;
      fileId: string;
      progress: number;
    }) => {
      updateUploadProgress(bytesTotal, bytesUploaded, fileId, progress);
    };

    const onUploadSuccess = ({ fileId }: { fileId: string }) => {
      setFileGraph(prevItems => {
        const newItems: NodeItem[] = [];

        for (const item of prevItems) {
          if (item.fileId !== fileId) newItems.push(item);
          else newItems.push({ ...item, status: 'uploaded' });
        }

        return newItems;
      });

      incrementFilesUploaded(1);
    };

    const onError = ({ error }: { error: Error }) => {
      if (shouldBlockErrorsRef.current) return;
      setError(error);
      throw error;
    };

    const onUploadError = ({ fileId, error }: { fileId: string; error: Error }) => {
      if (shouldBlockErrorsRef.current) return;
      setUploadError(error);
      setFileGraph(prevItems => {
        const newItems: NodeItem[] = [];

        for (const item of prevItems) {
          if (item.fileId !== fileId) newItems.push(item);
          else newItems.push({ ...item, status: 'failed' });
        }

        return newItems;
      });
      throw error;
    };

    on('addFiles', onAddFiles);
    on('fileTracked', onFileTracked);
    on('fileImported', onFileImported);
    on('progress', onProgress);
    on('updateGraph', onUpdateGraph);
    on('uploadProgress', onUploadProgress);
    on('uploadSuccess', onUploadSuccess);
    on('uploadError', onUploadError);
    on('error', onError);
    return () => {
      off('addFiles', onAddFiles);
      off('fileTracked', onFileTracked);
      off('fileImported', onFileImported);
      off('progress', onProgress);
      off('updateGraph', onUpdateGraph);
      off('uploadProgress', onUploadProgress);
      off('uploadSuccess', onUploadSuccess);
      off('uploadError', onUploadError);
      off('error', onError);
    };
  }, [createProject, handleReset, id, off, on, uploadState]);

  return (
    <Dialog.Root open={isUploadModalOpen} onOpenChange={setIsUploadModalOpen}>
      <Dialog.Trigger asChild>
        <Button
          filled
          primary
          active={isUploadModalOpen}
          className="w-30"
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.INITIATE_UPLOAD}
          disabled={disabled}
          size="s"
        >
          Upload
        </Button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <div
          className={cn(
            'fixed left-0 top-0 z-1 h-full w-full flex items-center justify-center',
            ' bg-black bg-opacity-30',
          )}
        >
          <Dialog.Content
            className={cn(
              'bg-neutral-100',
              'fixed',
              'flex-col',
              'flex',
              'inset-t-50% inset-l-50%',
              'p-6',
              'rounded-2',
              'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
              'transform-translate--50%',
              'w-700px',
              'mt-auto mb-auto',
              'max-h-90vh',
              'overflow-auto',
              'z-10',
            )}
            onPointerDownOutside={e => e.preventDefault()}
          >
            <div className="flex flex-col gap-3">
              <Dialog.Title className="flex-none color-neutral-800 typo-text-l">
                Upload files
              </Dialog.Title>

              <p className="color-neutral-500 typo-text-s">
                Directly upload files to the current Data Management folder only or select a project
                below to save to Project folder too.
              </p>
            </div>

            <FileSection
              addFiles={addFiles}
              canAddFiles={canAddFiles}
              fileGraph={fileGraph}
              stage={stage}
            />

            <OptionSection
              chunkSize={chunkOption}
              isDisabled={!canChangeOptions}
              setChunkSize={setChunkOption}
            />

            <ProjectSection
              isEnabled={canSaveToProject}
              parentNodeId={parentNodeId}
              projectId={projectId}
              onChangeParentNodeId={setParentNodeId}
              onChangeProjectId={handleChangeProjectId}
            />

            <div className="mt-3">
              {error && (
                <p className="text-right color-alert-400 typo-text-s">
                  Something went wrong during uploading. Please retry. <br />
                  {displayErrors(error)}
                </p>
              )}
            </div>

            <div className="mt-3 flex flex-none justify-end gap-3">
              {canCancel && (
                <Dialog.Close asChild>
                  <Button className="flex-1 cursor-pointer" size="s" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Dialog.Close>
              )}

              {!error && !canSave && (
                <Button
                  filled
                  primary
                  className="flex-1 cursor-pointer"
                  data-analytics-event-object={ANALYTICS_EVENT_OBJECT.START_UPLOADING}
                  disabled={!canUpload}
                  size="s"
                  onClick={handleUpload}
                >
                  Upload files
                </Button>
              )}

              {!error && canSave && (
                <Button
                  filled
                  primary
                  className="flex-1 cursor-pointer"
                  data-analytics-event-object={ANALYTICS_EVENT_OBJECT.CLOSE_UPLOAD_MODAL}
                  size="s"
                  onClick={handleCancel}
                >
                  Save and close
                </Button>
              )}

              {error && (
                <Button
                  filled
                  primary
                  className="flex-1 cursor-pointer"
                  size="s"
                  onClick={handleRetry}
                >
                  Retry
                </Button>
              )}
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
