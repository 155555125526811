import { ANALYTICS_EVENT_OBJECT } from '@/constants/analytics';
import { queryClient } from '@/graphql/client';
import { useEntitlements } from '@/hooks/useEntitlements';
import { setIsUploadModalOpen, useDownloadStore } from '@/stores/download';
import { cn } from '@/utils/classname';
import * as Dialog from '@radix-ui/react-dialog';
import { Button } from '@skand/ui';
import {
  AwsMultipartUploaderFactory,
  UploadSessionManager,
  useUploadSession,
} from '@skand/uploader';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  CAN_CHANGE_OPTIONS_STAGES,
  CAN_SAVE_TO_PROJECT_STAGES,
  CHUNK_SIZE_OPTION,
  ChunkSizeOption,
  DEFAULT_CHUNK_SIZE,
  DEFAULT_UPLOAD_SESSION_OPTIONS,
  FAILURE_STAGES,
  PREPARED_STAGES,
  PREPARING_STAGES,
  UPLOADING_STAGES,
} from './constants';
import { FileSection } from './FileSection/FileSection';
import { useCreateProjectNodeFiles, useCreateProjectNodeFolder } from './mutations';
import { OptionSection } from './OptionSection';
import { ProjectSection } from './ProjectSection';
import { awsMultipartRequestService, uploadSessionRequestService } from './requestServices';
import { resetUploadStore } from './uploadStore';
import { createProjectNodesFromFileTreeNode, displayErrors } from './utils';

interface UploadProps {
  disabled: boolean;
}

const defaultUploaderFactory = new AwsMultipartUploaderFactory(
  DEFAULT_CHUNK_SIZE,
  awsMultipartRequestService,
);

const uploadSessionManager = new UploadSessionManager(
  defaultUploaderFactory,
  uploadSessionRequestService,
  DEFAULT_UPLOAD_SESSION_OPTIONS,
);

export const Upload = ({ disabled }: UploadProps) => {
  const { id: parentSystemFolderNodeId } = useParams<{ id: string }>();

  const [projectId, setProjectId] = useState<null | string>(null);
  const [parentNodeId, setParentNodeId] = useState<null | string>(null);
  const { isUploadModalOpen } = useDownloadStore(state => state);
  const [projectError, setProjectError] = useState<null | unknown>(null);

  const [chunkSizeOption, setChunkSizeOption] = useState<ChunkSizeOption>('500M');
  const uploaderFactory = useMemo(() => {
    const chunkSize = CHUNK_SIZE_OPTION[chunkSizeOption];
    return new AwsMultipartUploaderFactory(chunkSize, awsMultipartRequestService);
  }, [chunkSizeOption]);

  const uploadSession = uploadSessionManager.useUploadSession(uploaderFactory);
  const createUploadSession = useCallback(() => {
    uploadSessionManager.createUploadSession(
      uploaderFactory,
      uploadSessionRequestService,
      DEFAULT_UPLOAD_SESSION_OPTIONS,
    );
  }, [uploaderFactory]);

  const { error: uploadSessionError, stage } = useUploadSession(uploadSession);
  const error = (uploadSessionError || projectError) as null | Error;

  const handleChangeProjectId = useCallback((id: null | string) => {
    setProjectId(prev => {
      if (prev === id) return prev;
      setParentNodeId(null);
      return id;
    });
  }, []);

  const canCancel = [
    ...PREPARING_STAGES,
    ...PREPARED_STAGES,
    ...UPLOADING_STAGES,
    ...FAILURE_STAGES,
  ].includes(stage);
  const canUpload = stage === 'fileImportRequests_creating_success';
  const canSave = stage === 'thumbnails_creating_success';
  const canChangeOptions = CAN_CHANGE_OPTIONS_STAGES.includes(stage);
  const canSaveToProject = CAN_SAVE_TO_PROJECT_STAGES.includes(stage);

  const handleUpload = () => uploadSession.continueToUploadFiles();

  const handleCancel = useCallback(() => {
    setIsUploadModalOpen(false);
    resetUploadStore();
    handleChangeProjectId(null);

    uploadSession.abort();
    createUploadSession();
  }, [createUploadSession, handleChangeProjectId, uploadSession]);

  const createProjectNodeFiles = useCreateProjectNodeFiles();
  const createProjectNodeFolder = useCreateProjectNodeFolder();
  const createProjectFileAndFolderNodes = useCallback(async () => {
    const root = uploadSession.fileTree.getNode('/');
    if (projectId && root && root.type === 'folder') {
      try {
        await createProjectNodesFromFileTreeNode(
          uploadSession.fileTree,
          root,
          projectId,
          parentNodeId,
          createProjectNodeFolder,
          createProjectNodeFiles,
        );
      } catch (e) {
        setProjectError(e);
      }
    }
  }, [
    createProjectNodeFiles,
    createProjectNodeFolder,
    parentNodeId,
    projectId,
    uploadSession.fileTree,
  ]);

  const handleRetry = useCallback(() => {
    if (projectError) {
      setProjectError(null);
      createProjectFileAndFolderNodes();
    } else if (uploadSessionError) {
      uploadSession.retry();
    }
  }, [createProjectFileAndFolderNodes, projectError, uploadSession, uploadSessionError]);

  useEffect(() => {
    const onAddFiles = () => {
      uploadSession.start({
        featureType: 'FILE_SYSTEM',
        parentSystemFolderNodeId,
        persistType: 'IMMEDIATE',
        priority: 'IMMEDIATE',
      });
    };

    const onSuccess = () => {
      createProjectFileAndFolderNodes();
      queryClient.invalidateQueries(['LIST_SYSTEM_NODES_BY_PARENT_NODE_ID']);
      queryClient.invalidateQueries(useEntitlements.queryKey);
    };

    const unsubscribeFileTreeChange = uploadSession.subscribeFileTreeChange(onAddFiles);
    const unsubscribeSuccess = uploadSession.subscribeSuccess(onSuccess);
    return () => {
      unsubscribeFileTreeChange();
      unsubscribeSuccess();
    };
  }, [createProjectFileAndFolderNodes, parentSystemFolderNodeId, uploadSession]);

  return (
    <Dialog.Root open={isUploadModalOpen} onOpenChange={setIsUploadModalOpen}>
      <Dialog.Trigger asChild>
        <Button
          filled
          primary
          active={isUploadModalOpen}
          className="w-30"
          data-analytics-event-object={ANALYTICS_EVENT_OBJECT.INITIATE_UPLOAD}
          disabled={disabled}
          size="s"
        >
          Upload
        </Button>
      </Dialog.Trigger>

      <Dialog.Portal>
        <div
          className={cn(
            'fixed left-0 top-0 z-1 h-full w-full flex items-center justify-center bg-black bg-opacity-30',
          )}
        >
          <Dialog.Content
            className={cn(
              'bg-neutral-100',
              'fixed',
              'flex-col',
              'flex',
              'inset-t-50% inset-l-50%',
              'p-6',
              'rounded-2',
              'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
              'transform-translate--50%',
              'w-700px',
              'mt-auto mb-auto',
              'max-h-90vh',
              'overflow-auto',
              'z-10',
            )}
            onPointerDownOutside={e => e.preventDefault()}
          >
            <div className="flex flex-col gap-3">
              <Dialog.Title className="flex-none color-neutral-800 typo-text-l">
                Upload files
              </Dialog.Title>

              <p className="color-neutral-500 typo-text-s">
                Directly upload files to the current Data Management folder only or select a project
                below to save to Project folder too.
              </p>
            </div>

            <FileSection uploadSession={uploadSession} />

            <OptionSection
              chunkSizeOption={chunkSizeOption}
              isDisabled={!canChangeOptions}
              setChunkSizeOption={setChunkSizeOption}
            />

            <ProjectSection
              isEnabled={canSaveToProject}
              parentNodeId={parentNodeId}
              projectId={projectId}
              onChangeParentNodeId={setParentNodeId}
              onChangeProjectId={handleChangeProjectId}
            />

            <div className="mt-3">
              {error && (
                <p className="text-right color-alert-400 typo-text-s">
                  Something went wrong during uploading. Please retry. <br />
                  {displayErrors(error)}
                </p>
              )}
            </div>

            <div className="mt-3 flex flex-none justify-end gap-3">
              {canCancel && (
                <Dialog.Close asChild>
                  <Button className="flex-1 cursor-pointer" size="s" onClick={handleCancel}>
                    Cancel
                  </Button>
                </Dialog.Close>
              )}

              {!error && !canSave && (
                <Button
                  filled
                  primary
                  className="flex-1 cursor-pointer"
                  data-analytics-event-object={ANALYTICS_EVENT_OBJECT.START_UPLOADING}
                  disabled={!canUpload}
                  size="s"
                  onClick={handleUpload}
                >
                  Upload files
                </Button>
              )}

              {!error && canSave && (
                <Button
                  filled
                  primary
                  className="flex-1 cursor-pointer"
                  data-analytics-event-object={ANALYTICS_EVENT_OBJECT.CLOSE_UPLOAD_MODAL}
                  size="s"
                  onClick={handleCancel}
                >
                  Save and close
                </Button>
              )}

              {error && (
                <Button
                  filled
                  primary
                  className="flex-1 cursor-pointer"
                  size="s"
                  onClick={handleRetry}
                >
                  Retry
                </Button>
              )}
            </div>
          </Dialog.Content>
        </div>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
