import { MoveIcon } from '@/components/MoveIcon';
import { setEditingMultiplSystemNodeId, setIsOpenMoveToDialog } from '@/stores/dataManagementStore';
import { useCallback, useMemo } from 'react';
import { FoldersPageTableData } from './useFoldersPageColumns';
import { useSystemNodesUserActionsPermissionsBySystemNodeIds } from '@/hooks/useSystemNodesUserActionsPermissionsBySystemNodeIds';

interface MoveMultipleFilesProps {
  selectedRows: FoldersPageTableData[];
}

export default function MoveMultipleFiles({ selectedRows }: MoveMultipleFilesProps) {
  const combinedNodeIds = useMemo(() => selectedRows.map(node => node.id), [selectedRows]);

  const { userHasMovePermission } = useSystemNodesUserActionsPermissionsBySystemNodeIds({
    systemNodeIds: combinedNodeIds,
  });

  const handleOpenMoveToDialog = useCallback(() => {
    setEditingMultiplSystemNodeId(combinedNodeIds);
    setIsOpenMoveToDialog(true);
  }, [combinedNodeIds]);

  return <MoveIcon disabled={!userHasMovePermission} onClick={() => handleOpenMoveToDialog()} />;
}
