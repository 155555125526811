export interface TableDataPlaceholderProps {
  isFetching: boolean;
}

export const TableDataPlaceholder = ({ isFetching }: TableDataPlaceholderProps) => {
  return (
    <tr className="h-4em flex items-center justify-center">
      <td className="color-neutral-400 typo-text-s">
        {isFetching ? 'Loading data...' : 'No data available'}
      </td>
    </tr>
  );
};
