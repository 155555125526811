import { DownloadIcon } from '@/components/DownloadIcon';
import { NODE_KIND } from '@/constants/node';
import { DownloadSystemNodesMutationVariables, File } from '@/graphql/codegen/graphql';
import { DOWNLOAD_SYSTEM_NODES } from '@/graphql/mutations';
import { request } from '@/graphql/request';
import { useGetSignedObjectDownloadUrl } from '@/hooks/useGetSignedObjectDownloadUrl';
import {
  downloadFiles,
  setDownloadNoteModal,
  setIsOpenDownloadDialog,
  setIsRequestingDownload,
} from '@/stores/download';
import { getShareLinkToken } from '@/utils/shareLink';
import { DATASETS_ADVANCED_DOWNLOADER } from '@/utils/split';
import { useTreatments } from '@splitsoftware/splitio-react';
import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';
import { FoldersPageTableData } from './useFoldersPageColumns';

interface DownloadMultipleFilesProps {
  selectedRows: FoldersPageTableData[];
}

export const DownloadMultipleFiles = ({ selectedRows }: DownloadMultipleFilesProps) => {
  const isShareLink = !!getShareLinkToken();
  const selectedFiles = selectedRows.filter(el => el.kind == NODE_KIND.FILE_NODE);
  const selectedFolders = selectedRows.filter(el => el.kind == NODE_KIND.FOLDER_NODE);
  const hasFiles = selectedFiles.length > 0;
  const hasFolders = selectedFolders.length > 0;
  const useHandleDownloadFile = hasFiles && selectedFiles.length < 50;

  const files = useGetSignedObjectDownloadUrl(selectedFiles.map(node => node.fileId as string));

  const treatments = useTreatments([DATASETS_ADVANCED_DOWNLOADER]);
  const isAdvancedDownloaderEnabled = treatments[DATASETS_ADVANCED_DOWNLOADER].treatment === 'on';

  const downloadSystemNodes = useMutation({
    mutationFn: (variables: DownloadSystemNodesMutationVariables) => {
      return request(DOWNLOAD_SYSTEM_NODES, variables);
    },
  });

  const handleDownloadFilesWithModal = useCallback(
    (zipFileName: string, zipFileNote: string) => {
      if (hasFolders && useHandleDownloadFile) {
        downloadFiles(files as File[], isAdvancedDownloaderEnabled);
        downloadSystemNodes.mutate({ nodeIds: selectedFolders.map(node => node.id) });
        setIsRequestingDownload(true);
        setIsOpenDownloadDialog(true);
        return;
      }

      downloadSystemNodes.mutate({
        nodeIds: selectedRows.map(node => node.id),
        name: zipFileName,
        note: zipFileNote,
      });
      setIsRequestingDownload(true);
      setIsOpenDownloadDialog(true);
    },
    [
      downloadSystemNodes,
      files,
      hasFolders,
      isAdvancedDownloaderEnabled,
      selectedFolders,
      selectedRows,
      useHandleDownloadFile,
    ],
  );

  const handleDownloadFile = useCallback(async () => {
    downloadFiles(files as File[], isAdvancedDownloaderEnabled);
    setIsOpenDownloadDialog(true);
  }, [files, isAdvancedDownloaderEnabled]);

  const handleDownloadModalSetup = () => {
    if (!hasFolders && useHandleDownloadFile) {
      handleDownloadFile();
    } else {
      setDownloadNoteModal({
        isOpen: true,
        actionFunction: (
          zipFileName: string,
          zipFileNote: string, // Updated type signature
        ) => handleDownloadFilesWithModal(zipFileName, zipFileNote),
        zipFileName: '',
        zipFileNote: '',
      });
    }
  };

  return (
    <DownloadIcon
      disabled={isShareLink && (hasFolders || !useHandleDownloadFile)}
      onClick={() => handleDownloadModalSetup()}
    />
  );
};
