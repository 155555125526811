import { cn } from '@skand/ui';
import { MouseEventHandler } from 'react';

export const DownloadIcon = ({ onClick, disabled = false }: { onClick: MouseEventHandler<HTMLElement>, disabled?: boolean }) => {
  return (
    <button
      className={cn('flex border-none bg-transparent', {
        'cursor-pointer': !disabled,
      })}
      disabled={disabled}
      onClick={onClick}
    >
      <svg
        fill='none'
        height="20"
        viewBox="0 0 21 20"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.9212 10.4889L10.9745 14.4356L7.02783 10.4889"
          stroke={!disabled ? '#726F74' : '#D8D6D9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.685"
        />
        <path
          d="M19.8399 14.8911V17.9378C19.8399 18.5489 19.3399 19.0489 18.7288 19.0489H3.22097C2.60986 19.0489 2.10986 18.5489 2.10986 17.9378V14.8911"
          stroke={!disabled ? '#726F74' : '#D8D6D9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.685"
        />
        <path
          d="M10.9901 14.2698V0.936462"
          stroke={!disabled ? '#726F74' : '#D8D6D9'}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="1.685"
        />
      </svg>
    </button>
  );
};
