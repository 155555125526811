import { TableDense } from '@/components/TableDense';
import { ProjectFolderNode } from '@/graphql/codegen/graphql';
import { useProjectFolderNodes } from '@/hooks/useProjectFolderNodes';
import { useProjectNode } from '@/hooks/useProjectNode';
import { useProjects } from '@/hooks/useProjects';
import { Button } from '@skand/ui';
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import dayjs from 'dayjs';
import { MouseEventHandler, useCallback, useMemo, useState } from 'react';
import { Breadcrumb } from './Breadcrumb';
import { SelectWithSearch } from './SelectWithSearch/SelectWithSearch';
import { assertIsString } from './utils';

const columnHelper = createColumnHelper<ProjectFolderNode>();

export interface ProjectSectionProps {
  isEnabled: boolean;
  onChangeProjectId: (projectId: null | string) => void;
  onChangeParentNodeId: (parentNodeId: null | string) => void;
  projectId: null | string;
  parentNodeId: null | string;
}

export const ProjectSection = ({
  isEnabled,
  onChangeParentNodeId,
  onChangeProjectId,
  projectId,
  parentNodeId,
}: ProjectSectionProps) => {
  const { projects } = useProjects();
  const { projectNodes } = useProjectFolderNodes(projectId, parentNodeId);
  const { projectNode } = useProjectNode(parentNodeId ?? undefined);

  const [sorting, setSorting] = useState<SortingState>([]);
  const columns = useMemo(() => {
    return [
      columnHelper.accessor('name', {
        header: 'name',
        cell: ({ getValue, row }) => {
          const handleClick: MouseEventHandler<HTMLSpanElement> = () => {
            if (!isEnabled) return;
            assertIsString(row.original.id);
            onChangeParentNodeId(row.original.id);
          };

          return (
            <span className="cursor-pointer underline typo-link-s" onClick={handleClick}>
              {getValue()}
            </span>
          );
        },
      }),
      columnHelper.accessor(row => dayjs(row.createdAt), {
        cell: ({ getValue }) => getValue().format('DD/MM/YYYY'),
        id: 'createdAt',
        header: 'date created',
        sortingFn: (a, b, id) => {
          const timeA = dayjs(a.getValue(id));
          const timeB = dayjs(b.getValue(id));
          return timeA.diff(timeB);
        },
      }),
    ];
  }, [isEnabled, onChangeParentNodeId]);

  const table = useReactTable({
    columns,
    data: projectNodes,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: { sorting },
  });

  const handleClear = useCallback(() => {
    onChangeParentNodeId(null);
    onChangeProjectId(null);
  }, [onChangeParentNodeId, onChangeProjectId]);

  return (
    <div>
      <div className="mt-6 flex flex-col items-start justify-between gap-3 b-1 b-neutral-400 rounded-2 b-solid p-3">
        <div className="h-fit w-full flex items-center justify-between">
          <div>
            <p className="color-neutral-800 typo-text-m">
              Save files to:{' '}
              <span className="color-neutral-500 typo-text-s">
                {projectNode?.name ?? 'Data Management only'}
              </span>
            </p>
          </div>
          <div className="flex gap-3">
            <SelectWithSearch
              disabled={!isEnabled}
              label="Select project (optional)"
              options={projects.map(item => {
                assertIsString(item?.id);
                assertIsString(item?.name);
                return {
                  key: item.id,
                  name: item.name,
                };
              })}
              value={projectId}
              width="full"
              onChange={onChangeProjectId}
            />

            {projectId !== null && (
              <Button disabled={!isEnabled} size="s" onClick={handleClear}>
                Clear
              </Button>
            )}
          </div>
        </div>
        {projectId && (
          <Breadcrumb
            disabled={!isEnabled}
            parentNodeId={parentNodeId}
            onChangeParentNodeId={onChangeParentNodeId}
          />
        )}
      </div>

      {projectId && (
        <div className="mt-6 h-240px flex-1 overflow-y-auto b-1 b-neutral-400 rounded-2 b-solid p-3">
          <TableDense contentType="FOLDER" disabled={!isEnabled} table={table} />
        </div>
      )}
    </div>
  );
};
