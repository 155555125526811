import { FolderNode } from '@/graphql/codegen/graphql';
import { useParentNodesChainByParentNodeIdQuery } from '@/hooks/useParentNodesChainByParentNodeIdQuery';
import { cn } from '@/utils/classname';
import { useMemo } from 'react';

export interface BreadcrumbProps {
  parentNodeId?: string;
  onNavigate: (folderNodeId?: string) => void;
  total?: number;
  isFetching?: boolean;
}

export const Breadcrumbs = ({ parentNodeId, total, isFetching, onNavigate }: BreadcrumbProps) => {
  const { parentNodesChain } = useParentNodesChainByParentNodeIdQuery(parentNodeId);

  const folderNodes = useMemo(() => {
    if (!parentNodesChain || !parentNodeId) return [];
    return structuredClone(parentNodesChain).reverse() as FolderNode[];
  }, [parentNodeId, parentNodesChain]);

  return (
    <nav className="">
      <ol className="inline list-none space-x-1">
        <li className="inline">
          <span
            className={cn(
              'color-neutral-400',
              parentNodeId
                ? 'typo-text-s-em hover:cursor-pointer'
                : 'no-underline hover:cursor-default typo-text-s',
            )}
            onClick={() => onNavigate()}
          >
            All folders
          </span>
        </li>

        {(folderNodes || []).map(folderNode => (
          <li className="inline" key={folderNode?.id}>
            <div className="inline">
              <span className="color-neutral-400 typo-text-s">&#47;</span>
              <span
                className={cn(
                  'ml-1 color-neutral-400',
                  parentNodeId !== folderNode?.id
                    ? 'typo-text-s-em hover:cursor-pointer'
                    : 'no-underline hover:cursor-default typo-text-s',
                )}
                onClick={() => onNavigate(folderNode?.id as string)}
              >
                {folderNode?.name}
              </span>
            </div>
          </li>
        ))}

        {!isFetching && !!total && (
          <li className="inline color-neutral-300 typo-text-s">({total})</li>
        )}
      </ol>
    </nav>
  );
};
