import { DownloadItem, cancelDownload, retryDownload } from '@/stores/download';
import { Progress } from '@skand/ui';

export interface DownloadItemRowProps {
  downloadItem: DownloadItem;
}

export const DownloadItemRow = ({ downloadItem }: DownloadItemRowProps) => {
  const { progress, name, error, url } = downloadItem;
  const percent = progress * 100;

  return (
    <>
      <p
        className="col-span-3 overflow-hidden text-ellipsis whitespace-nowrap color-neutral-800 typo-text-s"
        title={name}
      >
        {name}
      </p>

      <Progress className="col-span-2" progress={progress} />

      <div className="col-span-1 w-full flex items-center justify-end justify-self-end gap-2">
        {!!error && (
          <div
            className="i-skand-undo cursor-pointer text-3 color-neutral-500 hover:color-neutral-800"
            onClick={() => retryDownload(url)}
          />
        )}

        <div
          className="i-skand-closesmall cursor-pointer text-3 color-neutral-500 hover:color-neutral-800"
          onClick={() => cancelDownload(url)}
        />

        <p className="color-neutral-500 typo-text-s">{Math.trunc(percent)}%</p>
      </div>
    </>
  );
};
