interface ExtensionCellProps {
  getValue: () => string;
}

export const ExtensionCell = ({ getValue }: ExtensionCellProps) => {
  const extensionName = getValue();

  return (
    <div className="w-full">
      <div>
        <span className="color-neutral-600 typo-text-s">{extensionName}</span>
      </div>
    </div>
  );
};
