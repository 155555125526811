import {
  DownloadItem,
  setIsOpenDownloadDialog,
  setIsRequestingDownload,
  useDownloadStore,
} from '@/stores/download';
import { cn } from '@/utils/classname';
import { Toast } from '@skand/ui';
import { DownloadItemRow } from './DownloadItem';

export interface ItemRowProps {
  fileId: string;
}

export const Downloader = () => {
  const isOpenDownloadDialog = useDownloadStore(state => state.isOpen);
  const isRequestingDownload = useDownloadStore(state => state.isRequestingDownload);
  const downloadableFiles = useDownloadStore(state => state.files);
  const downloadables = Object.values(downloadableFiles);
  const isOpen = isOpenDownloadDialog && (downloadables.length !== 0 || isRequestingDownload);

  if (!isOpen) return null;
  return (
    <div
      className={cn(
        'b-1 b-neutral-600 b-solid rounded-2',
        'shadow-[0px_2px_2px_0px_rgba(0,0,0,0.15)]',
        'bg-neutral-100',
        'flex flex-col p-3 overflow-auto',
        'z-1 fixed max-h-50dvh',
        // extra small screen
        'inset-r-4 inset-b-10 w-[calc(100dvw-16px*2)] ',
        // small screen or larger
        'sm-w-480px',
      )}
    >
      <div className="mb-3 flex items-center justify-between">
        <h3 className="color-neutral-500 typo-text-m">Download status</h3>
        <div
          className={cn('i-skand-close text-3 color-neutral-400', 'hover:cursor-pointer')}
          onClick={() => {
            setIsRequestingDownload(false);
            setIsOpenDownloadDialog(false);
          }}
        />
      </div>

      {isRequestingDownload && (
        <div className={cn('w-[380px]', 'my-2')}>
          <Toast
            clickToDismiss
            message="Your download request has been received and is currently being processed. You'll receive an email once the zip file is ready for download."
            type="info"
          />
        </div>
      )}

      {downloadables.length !== 0 && (
        <div className="grid grid-cols-6 flex-none items-center gap-2">
          {downloadables.map((item: DownloadItem) => (
            <DownloadItemRow downloadItem={item} key={item.url} />
          ))}
        </div>
      )}
    </div>
  );
};
