import { SearchOption } from '@/constants/node';
import { UserV2 } from '@/graphql/codegen/graphql';
import { CheckBox } from '@skand/ui';
import { createColumnHelper } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { ExtensionCell } from './ExtensionCell';
import { NameCell } from './NameCell';
import { RowActionsContainer } from './RowActionsContainer';
import {
  setEditingSystemNodeId,
  setIsOpenEditAccessDialog,
  useDataManagementPageStore,
} from '@/stores/dataManagementStore';
import { useHandleUpdateDetails } from '@/hooks/useHandleUpdateDetails';

export interface FoldersPageTableData {
  id: string;
  kind: string;
  name: string;
  createdAt: string;
  extension: string;
  createdUser: UserV2;
  lastDownloadedAt: string;
  lastDownloadedUser: UserV2;
  fileId?: string;
  parentNodeId: string | null;
}

const columnHelper = createColumnHelper<FoldersPageTableData>();

export const useFoldersColumns = () => {
  const { searchKeyword, searchOption } = useDataManagementPageStore(state => state);

  const showParentNodesNameChain =
    searchKeyword.length > 0 && searchOption === SearchOption.RECURSIVE;

  const onUpdateDetails = useHandleUpdateDetails();

  const onEditAccess = useCallback((systemNodeId: string) => {
    setIsOpenEditAccessDialog(true);
    setEditingSystemNodeId(systemNodeId);
  }, []);

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        id: 'name',
        header: ({ table }) => (
          <label className="flex items-center gap-3 hover:cursor-pointer">
            <CheckBox
              checked={table.getIsAllRowsSelected()}
              className="hover:cursor-pointer"
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
            name
          </label>
        ),
        cell: ({ row, getValue }) => (
          <NameCell
            getValue={getValue}
            row={row}
            showParentNodesNameChain={showParentNodesNameChain}
          />
        ),
      }),

      columnHelper.accessor('createdAt', {
        header: 'date created',
        cell: ({ getValue }) => (
          <span className="typo-text-s">{new Date(getValue()).toLocaleString()}</span>
        ),
      }),

      columnHelper.accessor('extension', {
        header: 'extension',
        cell: ({ getValue }) => <ExtensionCell getValue={getValue} />,
      }),

      columnHelper.display({
        id: 'actions',
        header: undefined,
        cell: ({ row, table }) => (
          <RowActionsContainer
            row={row}
            table={table}
            onEditAccess={onEditAccess}
            onUpdateDetails={onUpdateDetails}
          />
        ),
      }),
    ],
    [showParentNodesNameChain, onUpdateDetails, onEditAccess],
  );

  return { columns };
};
