import { setDownloadNoteModal, useDownloadStore } from '@/stores/download';
import { cn } from '@/utils/classname';
import { Button, Input } from '@skand/ui';

const zipFileNameRegex = /^[a-zA-Z0-9_-]*$/;

export const DownloadNoteModal = () => {
  const { downloadNoteModalState } = useDownloadStore();

  const isZipFileNameValid = zipFileNameRegex.test(downloadNoteModalState.zipFileName);

  const handleCloseConfirmationModal = () => {
    setDownloadNoteModal({
      isOpen: false,
      actionFunction: () => {
        return;
      },
      zipFileName: '',
      zipFileNote: '',
    });
  };

  if (!downloadNoteModalState.isOpen) return null;

  return (
    <div
      className={cn(
        'fixed left-0 top-0 z-50 h-full w-full flex items-center justify-center',
        ' bg-black bg-opacity-30',
      )}
    >
      <div
        className={cn(
          'justify-between flex flex-col shadow-lg z-50 border-1 border-neutral-400 rounded-md border-solid bg-white',
          ' w-[438px] p-6 gap-6',
        )}
      >
        <div className="z-20 mt-1 flex flex-col gap-2">
          <p className="color-neutral-800 typo-text-m">Add a note</p>
          <p className="color-neutral-600 typo-text-s">
            This message will appear when the download link is opened in a browser
          </p>
        </div>
        <div>
          <Input
            hint={
              !isZipFileNameValid
                ? 'The file name must contain only alphanumeric characters, underscores, and hyphens.'
                : undefined
            }
            id="text"
            label="Zip File Name"
            name="text"
            value={downloadNoteModalState.zipFileName}
            warn={!isZipFileNameValid}
            onChange={(e) => {
              setDownloadNoteModal({
                ...downloadNoteModalState,
                zipFileName: e,
              });
            }}
          />
          <p className="mt-1 color-neutral-600 typo-text-xs">Optional</p>
        </div>
        <div className="h-120px w-390px">
          <textarea
            className="h-full w-full resize-none border-neutral-400 rounded p-2 outline-none typo-text-s"
            id=""
            name=""
            placeholder="Enter note"
            value={downloadNoteModalState.zipFileNote}
            onChange={e =>
              setDownloadNoteModal({
                ...downloadNoteModalState,
                zipFileNote: e.target.value,
              })
            }
          />
          <p className="color-neutral-600 typo-text-xs">Optional</p>
        </div>
        <div className="mt-4 w-full flex gap-2">
          <Button className="w-full cursor-pointer" size="s" onClick={handleCloseConfirmationModal}>
            Cancel
          </Button>
          <Button
            filled
            primary
            className="w-full cursor-pointer"
            disabled={!isZipFileNameValid}
            size="s"
            onClick={() => {
              downloadNoteModalState.actionFunction(
                downloadNoteModalState.zipFileName,
                downloadNoteModalState.zipFileNote,
              );
              handleCloseConfirmationModal();
            }}
          >
            Download
          </Button>
        </div>
      </div>
    </div>
  );
};
